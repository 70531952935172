// Form - Input
.ant-form-item {
  .ant-input-disabled {
    background-color: #dadada;
  }

  .ant-select-disabled {
    background-color: transparent !important;

    .ant-select-selector {
      background-color: #26292F !important;
    }
  }
}

.ant-input {
  border-radius: 5px !important;
  background: #15171c !important;
  border: 1px solid #27272e !important;
}

.ant-select-selection-search-input {
  color: #ffffff !important;
}

.ant-select-dropdown {
  background-color: #1a1a1a;

  .ant-select-item-option-selected,
  .ant-select-item-option-active {
    background-color: #0f1013;
  }

  .ant-select-item-option-content {
    color: #adaec1;
  }
}

.ant-select-dropdown-empty .ant-empty-description {
  color: #adaec1;
}

.ant-switch {
  min-width: 32px;
  border-radius: 30%;
  border: 1px solid #60646e;

  .ant-switch-handle {
    top: 3px;
    width: 14px;
    height: 14px;

    &::before {
      border-radius: 5px;
      background-color: #60646e;
    }
  }
}

.ant-switch-checked {
  background-color: #3699ff;

  .ant-switch-handle {
    left: calc(100% - 18px - -1px);

    &::before {
      background-color: #ffffff;
    }
  }
}

.ant-form-item-control {
  .ant-input-affix-wrapper,
  .ant-select-selector {
    border: 1px solid #27272e !important;
    background: #15171c !important;
    height: 34px !important;
    gap: 7px !important;
    border-radius: 5px !important;
    padding: 0 14px !important;

    .ant-select-selection-item {
      line-height: normal;
      color: #a1a5b7;
      font-family: 'YuGothic';
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
    }

    .ant-input {
      border: none !important;
    }
  }

  .ant-input,
  .ant-select-arrow {
    color: #adaec1;
  }

  .ant-input-password {
    border-radius: 5px !important;
    border: 1px solid #27272e !important;
    background: #15171c !important;
    gap: 0 !important;

    .ant-input {
      border: none !important;
      border-radius: 0 !important;
    }

    svg {
      color: #fff;
    }
  }

  .ant-input-disabled,
  .ant-input-affix-wrapper-disabled {
    background-color: #26292f !important;
    color: #595b69;
    border: 1px solid #30313b !important;
  }
}

.ant-form-item-no-colon {
  color: #adaec1 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.ant-select-selection-placeholder,
.ant-input {
  &&::placeholder {
    color: #595b69;
    font-weight: 400;
    font-size: 12px;
  }

  color: #595b69;
  font-weight: 400;
  font-size: 12px;
}

// Pagination

.ant-pagination {
  .ant-pagination-item-link {
    border-radius: 5px;
    border: none;
    width: 30px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2c2f36;
  }

  .ant-pagination-item {
    background-color: transparent;
    border: none;

    a {
      color: #7e8299;
      font-family: 'YuGothic';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
  }

  .ant-pagination-item-active {
    border-radius: 5px;
    background-color: #3699ff;
    width: 30px;
    height: 28px;
    border: none;
    margin-bottom: 4px;

    a {
      color: #ffffff !important;
    }
  }

  .anticon {
    color: #9091a5;
  }
}

// Modal

.ant-modal-root {
  .ant-modal-mask {
    display: none;
  }

  .ant-modal {
    display: flex;
    align-items: center;
    justify-self: center;
    top: 0;
    height: 100%;

    .ant-modal-wrap {
      display: flex;
      align-items: center;
      justify-self: center;

      .ant-modal-content {
        margin: 0;
      }
    }
  }

  .mode_zoom-video {
    height: calc(100vh - 44px) !important;
    width: 100vw !important;
    top: 44px;
    padding: 0;
    margin: 0;
    max-width: 100%;

    .ant-modal-content {
      height: 100%;
      width: 100% !important;

      .ant-modal-close {
        display: none;
      }

      .icon-close-modal {
        position: absolute;
        right: 40px;
        height: 74px;
        display: flex;
        align-items: center;
        top: 0;
      }

      .icon-muted {
        position: absolute;
        right: 80px;
        height: 74px;
        display: flex;
        align-items: center;
        top: 0;
      }

      .icon-close {
        position: absolute;
        right: 90px;
        height: 74px;
        display: flex;
        align-items: center;
        top: 0px;
      }

      .ant-modal-header {
        background: #141414;
        height: 74px;
      }

      .ant-modal-body {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 80px);
        padding: 30px 40px;

        .video-zoom__wrap {
          position: relative;
          display: flex;
          justify-content: center;
          height: 100%;
        }

        .switch-icon {
          position: absolute;
          right: 36px;
          top: 30px;
          z-index: 2;
        }

        .video-item {
          height: 100%;
          object-fit: cover;

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .title {
      color: #ffffff;
      display: flex;
      align-items: center;

      .serial-header {
        font-weight: 400;
        color: #adaec1;
        margin-left: 5px;
        font-size: 14px;
      }
    }

    .col-info {
      display: flex;
      justify-content: center;

      .text-info {
        border-radius: 8px;
        padding: 10px;
        margin: 0 10px;
        display: flex;
        justify-content: space-between;
        border: none;
        color: #595b69;
        font-size: 18px;
        font-weight: 700;
        min-width: 217px;

        .ant-typography {
          font-size: 24px;
        }
      }

      .ant-typography {
        color: #ffffff;
      }
    }

    &.alert-normal {
      .ant-modal-header {
        border-bottom: none !important;
        box-shadow: 0px 5px 8px 0px rgba(70, 78, 95, 0.02);

        .text-info {
          background: #1f2126;
        }

        .serial-header {
          font-weight: 400;
          color: #ffffff;
          margin-left: 20px;
          font-size: 14px;
        }
      }
    }

    &.alert-primary {
      .ant-modal-header {
        border-bottom: 1px dashed #ffffff !important;
        background-color: #f64e60;

        .text-info {
          background: #fc6b7a;
        }
      }
    }

    &.alert-muted {
      .ant-modal-header {
        border-bottom: 1px dashed #ffffff !important;
        background-color: #d9aa00;

        .text-info {
          background: #eabc15;
        }
      }
    }
  }

  .mode_zoom-video-dashboard {
    top: 0;
    height: 100% !important;
  }
}

.ant-modal-confirm {
  display: flex;
  width: 100%;
  justify-content: center;

  .ant-modal-content {
    background-color: #15171c;
    min-width: 494px;
    border-radius: 12px;

    .ant-modal-body {
      padding: 30px 0;
      width: 100%;

      .ant-modal-confirm-body {
        padding: 0 30px;
        display: grid;

        .ant-modal-confirm-title {
          color: #ffffff;
          text-align: center;
          margin-top: 30px;
        }
      }
    }

    .ant-modal-confirm-btns {
      border-top: 1px solid #383840;
      display: flex;
      justify-content: center;
      padding-top: 30px;

      .ant-btn-default,
      .ant-btn-primary {
        min-width: 140px;
        height: 48px;
        padding: 12px 20px;
        border-radius: 6px;
        font-weight: 400;
      }

      .ant-btn-default {
        background: #2c2f36;
        border: none;
        color: #adaec1;
      }
    }
  }

  @media only screen and (max-width: 495px) {
    margin: 0 auto;
    justify-content: center;

    .ant-modal-content {
      min-width: calc(100% - 30px);
    }

    .ant-modal-content {
      .ant-modal-confirm-btns {
        .ant-btn {
          min-width: 90px;
          height: 35px;
          padding: 8px 20px;
        }
      }
    }
  }
}

.pop-up-confirm-audio {
  align-items: start !important;

  * {
    font-family: 'Helvetica';
  }

  .ant-modal-content {
    background-color: #1f1f1f !important;
    border: 1px solid #444746 !important;
    border-radius: 20px;
    overflow: hidden;

    .ant-modal-header {
      background-color: #1f1f1f !important;
      border-bottom: none;
      padding: 20px;

      .ant-modal-title {
        color: #e3e3e3 !important;
      }
    }

    .ant-modal-body {
      padding: 0;

      .ant-row {
        justify-content: end;
        padding: 20px;
        padding-top: 0;

        .col-btn-ok {
          background: #adc5f7 !important;
          height: 38px;
          border-radius: 20px;
          padding: 12px 22px;
          align-items: center;
          justify-content: center;
          display: flex;
          position: relative;
          cursor: pointer;

          .ant-typography {
            color: #000000 !important;
          }

          &::after {
            background-color: transparent;
            position: absolute;
            height: 46px;
            width: 80px;
            top: -4px;
            left: -4px;
            content: '';
            border: 2px solid #adc5f7;
            border-radius: 22px;
          }
        }
      }
    }
  }
}

.modal-setting {
  .ant-switch {
    height: 20px;
    width: 42px;
    border-radius: 10px;

    .ant-switch-handle {
      top: 1px;
      width: 16px;
      height: 16px;
      border-radius: 48px;

      &::before {
        border-radius: inherit;
      }
    }
  }
}

// Zoom & Alert

.is-zoom {
  border: 1px solid #1f2126;
  background: #15171c;
}

.alert-normal {
  background-color: #15171c;

  .ant-modal-content {
    background-color: #15171c !important;
  }

  .text-content {
    border: none !important;
    background-color: #1f2126 !important;
  }
}

.alert-primary {
  border-radius: 5px;
  border: 1px solid #f64e60 !important;
  background: #f64e60 !important;
  box-shadow: 0px 0px 20px 0px rgba(246, 78, 96, 0.5);

  .ant-typography {
    color: #ffffff !important;
  }

  .ant-modal-content {
    background: #f64e60 !important;
  }

  .col-info {
    .title {
      background-color: #fc6b7a !important;
      border: none !important;
    }
  }

  .text-content {
    background-color: #fc6b7a !important;
    border: none !important;

    .name {
      color: #ffffff !important;
    }
  }

  .col-title-type-18 {
    .text-group {
      background-color: #fc6b7a !important;
    }
  }
}

.alert-muted {
  border-radius: 5px;
  background: #d9aa00 !important;
  box-shadow: 0px 0px 20px 0px rgba(217, 170, 0, 0.5) !important;

  .ant-modal-content {
    background: #d9aa00 !important;
  }

  .ant-typography {
    color: #ffffff !important;
  }

  .col-info {
    .title {
      background-color: #eabc15 !important;
      border: none !important;
    }
  }

  .text-content {
    border: none !important;
    background-color: #eabc15 !important;

    .name {
      color: #ffffff !important;
    }
  }

  .col-title-type-18 {
    .text-group {
      background-color: #eabc15 !important;
    }
  }
}

.ant-menu-inline-collapsed-tooltip,
.ant-menu-submenu-popup {
  display: none;
}

.ant-modal.modal-custom-antd {
  padding-bottom: 0;

  .ant-modal-content {
    background-color: #15171c;
    border-radius: 12px;
    overflow: hidden;

    .ant-modal-header {
      background-color: #15171c;
      border-bottom: 1px solid #383840;
      height: 114px;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-modal-title {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
      }
    }

    .ant-modal-body {
      padding: 0 30px;
      height: calc(100% - 114px - 53px);
      overflow: auto;

      .ant-input-affix-wrapper {
        padding-left: 5px !important;
      }
    }

    .ant-modal-footer {
      border-top: 1px solid #383840;
      display: flex;
      justify-content: center;
      padding: 30px 15px;

      .ant-btn {
        min-width: 140px;
        height: 48px;
      }

      .btn-cancel {
        color: #adaec1;
        font-size: 15px;
      }

      .btn-submit {
        font-size: 15px;
      }

      .col-group-btn {
        display: flex;
        justify-content: center;
      }

      .col-action {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid #383840;
        text-align: center;

        .ant-typography {
          color: #adaec1;
          text-decoration: underline;
          cursor: pointer;
          font-size: 12px;
        }
      }
    }
  }
}

h1.ant-typography {
  color: #fff;
  margin-top: 30px;
}

// icon
.icon-disable {
  cursor: not-allowed;
  opacity: 0.4;
}

// Required Field
.required-field {
  &::after {
    content: '*';
    color: #f64e60;
    margin-left: 3px;
  }
}

// Color
.color-primary {
  color: #adaec1;
}

// Dropdown
.ant-dropdown {
  min-width: 149px;
  min-height: 88px;

  .ant-dropdown-menu {
    background: #1a1a1f;
    height: 100%;
    width: 100%;
    padding: 20px;
    border-radius: 8px;

    .ant-dropdown-menu-item {
      margin-bottom: 20px;

      &:hover {
        background-color: #15171c;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .ant-dropdown-menu-item-active {
      background-color: transparent;
    }

    .ant-dropdown-menu-title-content {
      .ant-typography {
        color: #adaec1;
      }
    }
  }
}

// Video Streaming
.streaming-player {
  height: calc(100% + 1px);
  aspect-ratio: 16/9;
  border-radius: 8px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.streaming-status {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
  display: none;
  width: 8px;
  height: 8px;
  border-radius: 8px;
}

.no-camera {
  position: absolute;
  top: 0;
  left: -1px;
  z-index: 1;
  display: none;
}

.ant-modal-root {
  .streaming-player {
    width: 100.1%;
  }

  .no-camera {
    left: -1px;
  }
}

.font-helvetica {
  font-family: helvetica;
}

.layout-pin {
  .ant-list-item {
    .ant-card-head {
      .ant-card-head-title {
        .row-title {
          .col-title {
            .name {
              font-size: 10px !important;
            }

            .value {
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.quantity-wait-diplay {
  color: #f64e60;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 20px;
  position: absolute;
  z-index: 1;
}

// text-tooltip
.text-tooltip-error {
  white-space: pre-wrap;
  font-size: 12px;
  margin: 0;
}

.block-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
 }

 .ellipsis-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
 }

// modal background

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.modal-set-divice {
  .rc-virtual-list-holder {
    max-height: 100px !important;
    overflow-y: auto !important;

    &::-webkit-scrollbar-track {
      background: transparent !important;
    }
  }

  .rc-virtual-list-scrollbar {
    display: none !important;
  }

  .ant-form-item-control {
    .ant-input-affix-wrapper,
    .ant-select-selector {
      height: 42px !important;
    }

    .ant-form-item-control-input-content {
      .ant-input {
        height: 42px !important;
      }
    }
  }

  .ant-btn {
    span {
      font-size: 15px;
    }
  }
}

// no data

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-data p {
  color: #fff;
  margin: 0;
}

.ant-progress-circle .ant-progress-text {
  font-family: 'Helvetica';
  color: #fff !important;
}

.cursor-pointer {
  cursor: pointer;
}
