@font-face {
  font-family: 'YuGothic';
  src: url('./yugothib.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./Helvetica.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
