.root-spin {
  height: 100%;
  width: 100%;

  div:first-child {
    .ant-spin-spinning {
      max-height: unset;
    }
  }

  .ant-spin-container {
    height: 100%;
    width: 100%;
  }

  .ant-spin-blur {
    opacity: 0;
  }
}
